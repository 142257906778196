import React, { Component } from "react";
import turkey from "../assets/parkcityturkeydrive_2024.jpg";
import venmoQr from "../assets/venmo-qr.png";
import "./App.scss";

const PaypalButton = () => (
  <div>
    <a href="https://paypal.me/PCBRturkeydrive" className="button">
      <i className="fal fa-hand-holding-usd" /> Donate Using PayPal
    </a>
  </div>
);

const FacebookButton = () => (
  <div>
    <a href="https://www.facebook.com/parkcityturkeydrive/" className="button">
      <i className="fab fa-facebook-square" /> Facebook Page
    </a>
  </div>
);

const VenmoButton = () => (
  <div>
    <a
      href="https://venmo.com/code?user_id=2878067869483008975"
      className="button"
    >
      <i className="fal fa-hand-holding-usd" /> Donate Using Venmo
    </a>
  </div>
);

const Info = () => (
  <div className="App__info">
    <div className="App__info__buttonSet">
      <VenmoButton />
      <PaypalButton />
      <FacebookButton />
    </div>
    <div>
      <a href="https://venmo.com/code?user_id=2878067869483008975">
        <img src={venmoQr} alt="" className="qr" />
      </a>
    </div>
  </div>
);

class App extends Component {
  render() {
    return (
      <div className="App">
        <Info />
        <div className="App__content">
          <img src={turkey} alt="2024 Park City Turkey Drive" />
          <p>
            The 23nd annual Park City Turkey Drive will be held on Thursday,
            November 21st and Friday, November 22nd from 10am-5pm at the Market
            at Park City.
          </p>
        </div>
        <Info />
      </div>
    );
  }
}

export default App;
